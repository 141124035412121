import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import Title from "../../components/title"
// import Hero from "../../blocks/Hero"
import { FixedBanner } from "../../blocks/Banner"
import TextWithImage from "../../blocks/TextWithImage"

const Escrow = () => (
  <Layout>
    {/* <Hero
      data={{
        title: "Paybase for escrow providers",
        copy:
          '"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec efficitur non lorem ultrices lobortis. Donec in mauris sed eros volutpat tempor eget nec purus. Duis sagittis tempor volutpat. Pellentesque ornare justo in felis efficitur, non semper nisi imperdiet. Morbi rutrum tellus nisl."',
        companyLogo: {
          src: "/who-is-it-for/marketplace/airbnb.png",
          alt: "John Smith, Product manager at Fast banana",
          copy: "John Smith, Product manager at Fast banana",
        },
        image: {
          src: "/who-is-it-for/escrow/img1.png",
          alt: "placeholder image",
        },
        color1: "green",
        color2: "darkgreen",
        color3: "rose",
      }}
    /> */}
    <Helmet>
      <title>Paybase - Escrow</title>
      <meta property="og:title" content="Paybase - Escrow" />
    </Helmet>
    <TextWithImage
      data={{
        title: "White Label Escrow",
        content:
          "Conceptualise and configure your own escrow solution via our API and safeguard transactions with our white label solution. Hold, divide or return payments and seamlessly manage dispute resolution end-to-end. With Paybase, you’re covered to operate under our regulatory umbrella, enabling you to stay in control and do so compliantly.",
        image: {
          src: "/who-is-it-for/escrow/img1.svg",
          alt: "placeholder alt",
        },
        links: [
          { copy: "Get in touch", to: "https://trustshare.co" },
          {
            copy: "Whitepaper",
            to: "https://trustshare.co",
            bg: "rose",
            shadow: "white",
            hoverColor: "black",
          },
          {
            copy: "Developer Centre",
            to: "https://docs.trustshare.io",
            color: "white",
            bg: "black",
            shadow: "white",
            hoverColor: "black",
          },
        ],
      }}
      options={{
        titleColor: "blue",
        copyColor: "grey",
        backgroundColor: "lightblue",
        padding: "3rem",
      }}
    />
    <Title copy="Using Paybase’s capabilities on your escrow platform?" />
    <TextWithImage
      data={{
        title: "Escrow Milestones_",
        content:
          "Set up milestones to ensure users are paid quickly and efficiently, even on long projects. A home renovation, for instance, can be split into smaller tasks that are approved and paid-for as they are completed. The release of funds can furthermore be automated enabling you to maximise optimisation for your end-users.",
        image: {
          src: "/who-is-it-for/escrow/img2.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#escrow" }],
      }}
      options={{ titleColor: "purple" }}
    />
    <TextWithImage
      data={{
        title: "Dispute Resolution_",
        content:
          "Hold, divide or return funds in escrow with our industry-leading solution. Hold the decision-making reins on how disputes should be handled and retain control of how your product is managed. If an item is received over one week late, for example, you can automate 75% of the funds being sent to the seller, with 25% being returned to the buyer. Let us help you streamline your product, end-to-end.",
        image: {
          src: "/who-is-it-for/escrow/img3.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Find out more", to: "/what-we-offer#escrow" }],
      }}
      options={{ isTextRight: true, titleColor: "darkgreen" }}
    />
    <TextWithImage
      data={{
        title: "White Label Solution_",
        content:
          "Retain control of your branding, your pricing and the way that you communicate with your users. The Paybase white label escrow solution enables you to build the product you want and to operate it the way that suits you. Take advantage of our market-leading capabilities without diverting users away from your site.",
        image: {
          src: "/who-is-it-for/escrow/img4.svg",
          alt: "placeholder alt",
        },
        links: [{ copy: "Get In Touch", to: "https://trustshare.co" }],
      }}
      options={{ titleColor: "blue" }}
    />
    <FixedBanner />
  </Layout>
)

export default Escrow
