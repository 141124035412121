import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Text from "../components/text"
import { Link, TextLink } from "../components/link"
import { getBg } from "../theme"

export type Props = {
  data: {
    title: string
    copy: string
    link: LinkType
  }
  options?: {
    backgroundColor?: ColorKeys
  }
}

const Wrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto;
`

const LimitWiderPart = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  padding: 0 2rem;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
  }
`
const BG = styled.div<{ bg?: ColorKeys }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: ${getBg};
`

const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: center;
  padding: 3rem 0;
  ${up("tablet")} {
    padding: 3rem 5rem;
    align-items: flex-start;
    justify-items: flex-start;
    grid-template-columns: 1fr;
  }
  ${up("desktop")} {
    padding: 3rem 0;
    align-items: flex-start;
    justify-items: flex-start;
    grid-template-columns: 1fr auto;
  }
`
const Content = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 0.4rem;
  text-align: center;
  align-items: center;
  justify-items: center;
  ${up("tablet")} {
    text-align: left;
    align-items: flex-start;
    justify-items: flex-start;
  }
`

const Banner: React.FC<Props> = ({ data, options }) => {
  return (
    <Wrapper>
      <BG bg={options ? options.backgroundColor : undefined} />
      <LimitWiderPart>
        <Container>
          <Content>
            <Text type="h4" color="yellow">
              {data.title}
            </Text>
            <Text
              color={options && options.backgroundColor ? "white" : "black"}
            >
              {data.copy}
            </Text>
          </Content>
          <Link to={data.link.to}>{data.link.copy}</Link>
        </Container>
      </LimitWiderPart>
    </Wrapper>
  )
}

export const FixedBanner = () => (
  <Wrapper>
    <BG bg="purple" />
    <LimitWiderPart>
      <Container>
        <Content>
          <Text type="h4" color="yellow">
            Can't see what you're looking for?
          </Text>
          <Text color="white">
            Check out our extended list of capabilities{" "}
            <TextLink
              to="/what-we-offer"
              color="yellow"
              hoverColor="rose"
              hoverColor2="yellow"
            >
              here
            </TextLink>
          </Text>
        </Content>
        <Link to="/what-we-offer">View all capabilities</Link>
      </Container>
    </LimitWiderPart>
  </Wrapper>
)
export default Banner
